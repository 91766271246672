
	import { Component, Vue } from "vue-property-decorator";
	import { Button, Cascader, Form, FormItem, Input, InputNumber, Modal, Tag, Upload } from "view-design";
	import request from "@/request";
	import { CreateElement, VNode } from "vue";

	interface leaf {
		label:string,
		value:number,
		loading?:boolean,
		children?:leaf[]
	}

	@Component({
		components: {
			Form,
			FormItem,
			Upload,
			Button,
			Input,
			InputNumber,
			Tag,
			Cascader
		}
	})
		export default class Bill extends Vue {
		chunks:{ id:number, number:number, name:string }[] = [];
		backup:leaf[] = [];
		count:number = 0;
		backupValue:number[] = [];
		describe:string = "";
		fee:number = 0;

		previewFile (file:any) {
			let url = file.response.data.fileurl;
			if (!file.response.data.image) {
				window.open(url);
			} else {
				const config = {
					render (h:CreateElement):VNode {
						return h("img", { attrs: { src: url }, staticStyle: { width: "100%" } });
					},
					width: 800
				};
				// @ts-ignore
				Modal.info(config);
			}
		}

		loadData (item:leaf, callback:Function) {
			item.loading = true;
			request({
				url: "/repair/rest/spares/",
				params: {
					stype: item.value
				}
			}).then(({ data }) => {
				item.loading = false;
				item.children = data.map((t:any):leaf => ({
					label: t.name,
					value: t.id
				}));
				callback();
			});
		}

		handleExceeded () {
			this.$Notice.error({ desc: "文件大小超出限制" });
		}

		add () {
			if (this.backupValue.length === 2 && this.count !== 0) {
				const selected:leaf = (this.$refs.backup as any).selected[ 1 ];
				const origin = this.chunks.find(t => t.id === selected.value);
				if (origin) {
					origin.number += this.count;
				} else {
					this.chunks.push({
						id: selected.value,
						number: this.count,
						name: selected.label
					});
				}
			} else {
				this.$Notice.error({ desc: "请选择正确的物料与数量！" });
			}
		}

		removeChunk (e:MouseEvent, name:number) {
			const origin = this.chunks.findIndex(t => t.id === name);
			if (origin > -1) {
				this.chunks.splice(origin, 1);
			}
		}

		mounted () {
			request({
				url: "/repair/rest/sparestype/"
			}).then(({ data }) => {
				this.backup = data.map((t:any):leaf => ({
					label: t.name,
					value: t.id,
					children: [],
					loading: false
				}));
			});
		}

		getInfo (oid:string):any {
			return {
				opt: "dealevent",
				describe: this.describe,
				eid: this.$route.params.id,
				oid,
				fileids: (this.$refs.upload as any).fileList.map((t:any) => t.response.data.docId),
				spares: this.chunks.map((t) => ({ id: t.id, number: t.number })),
				maintainFee: this.fee + "元"
			};
		}
	}
